import { Capacitor } from "@capacitor/core";
import { useHistory, useParams } from "react-router";
import Button from "../../../../../../components/button";
import Translation from "../../../../../../components/translation";
import { useOverlay } from "../../../../../../contexts/overlay/overlay-context";
import { useProfile } from "../../../../../../contexts/profile";
import { useSafeArea } from "../../../../../../contexts/safe-area";
import { useGetBasketProducts } from "../../../../../../go-services/basket-product/basket-product";
import { useGetBasketSubProducts } from "../../../../../../go-services/basket/basket";
import useMedia from "../../../../../../hooks/useMedia";
import { CompanyRouteParams } from "../../../../../../types/params/company-route";
import CrossSellingOverlay from "../../../restaurant/components/cross-selling-overlay";
import { ReactComponent as ArrowRight } from "./assets/arrow-right.svg";
import { useOneCompanyQuery } from "../../../../../../queries/company";
import { useOneBasketQuery } from "../../../../../../queries/basket";

const ConfirmBasketPanel = () => {
    //Attributes
    const { insets } = useSafeArea();
    const overlay = useOverlay();
    const { user } = useProfile();
    const history = useHistory();
    const { companyId: companyName } = useParams<CompanyRouteParams>();
    const { showBasicPanel } = useOverlay();

    // Queries
    const { data: companyQuery } = useOneCompanyQuery(companyName);
    const companyId = companyQuery?.id;
    const { data: currentBasket } = useOneBasketQuery(
        companyId!,
        user.employeeId
    );

    const { data: basketProducts } = useGetBasketProducts(
        user?.employeeId,
        currentBasket?.id ?? "",
        { query: { enabled: !!user?.employeeId && !!currentBasket?.id } }
    );

    const { data: subProducts } = useGetBasketSubProducts(
        user.employeeId,
        currentBasket?.id ?? "",
        { isAvailable: true },
        { query: { enabled: !!currentBasket?.id } }
    );

    //Functions
    const handleConfirm = () => {
        overlay.removeCurrentOverlay();
        history.push(`/${companyName}/order-overview`);
        if (
            subProducts?.data &&
            subProducts?.data?.filter((product) => !product?.isOutOfStock)
                ?.length > 0
        ) {
            setTimeout(() => {
                showBasicPanel({
                    content: <CrossSellingOverlay />,
                    animate: true,
                });
            }, 300);
        }
    };

    //UseMedia
    const currentMediaQuery = useMedia(
        // Media queries
        ["(min-width: 600px)", "(max-width: 599px)"],
        // Column counts (relates to above media queries by array index)
        [1, 2],
        // Default column count
        1
    );

    // Render
    return (
        <div
            onClick={currentMediaQuery === 2 ? handleConfirm : () => {}}
            className={
                basketProducts && basketProducts?.length > 0
                    ? "bg-beige p-4 min-w-full z-10 md:mb-auto md:max-w-md"
                    : "hidden"
            }
            style={{
                marginBottom:
                    Capacitor.getPlatform() === "ios"
                        ? insets?.bottom
                        : undefined,
            }}
        >
            <Button
                onClick={currentMediaQuery === 2 ? () => {} : handleConfirm}
                className={
                    "min-w-full max-w-full text-white bg-black rounded-lg  min-h-11! max-h-11! p-4 md:max-w-md cursor-pointer"
                }
                data-cy="confirmOrder"
            >
                <div className={"flex justify-between items-center "}>
                    <div className="price font-semibold text-sm">
                        {currentBasket?.totalPrice?.toFixed(2)} EUR
                    </div>
                    <div className="add-to-basket text-sm font-semibold flex flex-row">
                        <div className="mr-2">
                            <Translation>confirmOrder</Translation>
                        </div>
                        <div className="my-auto">
                            <ArrowRight />
                        </div>
                    </div>
                </div>
            </Button>
        </div>
    );
};

export default ConfirmBasketPanel;
