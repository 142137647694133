import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import Paragraph from "../../../../../../../../components/paragraph";
import { useOverlay } from "../../../../../../../../contexts/overlay/overlay-context";
import { useProfile } from "../../../../../../../../contexts/profile";
import { useOneBasketQuery } from "../../../../../../../../queries/basket";
import { useOneCompanyQuery } from "../../../../../../../../queries/company";
import BasketProductService from "../../../../../../../../services/BasketProductService";
import { BasketProductDTO } from "../../../../../../../../types/dtos/basket-product";
import { ProductPriority } from "../../../../../../../../types/enums/product-priority";
import { CompanyRouteParams } from "../../../../../../../../types/params/company-route";
import { SearchProductCardProps } from "./props";
import parse from "html-react-parser";
import AddToBasket from "../../../../../home/components/product-overlay/components/add-to-basket";
import { debounce } from "lodash";
import ProductOverlay from "../../../../../home/components/product-overlay";
import { useGetBasketProducts } from "../../../../../../../../go-services/basket-product/basket-product";
import { useQueryClient } from "react-query";
import { QueryKey } from "../../../../../../../../types/enums/query-key";

function SearchProductCard(props: SearchProductCardProps) {
    // Attributes
    const queryClient = useQueryClient();
    const { user } = useProfile();
    const { product } = props;
    const { companyId: companyName } = useParams<CompanyRouteParams>();
    const { data: companyQuery } = useOneCompanyQuery(companyName);
    const companyId = companyQuery?.id;
    const { data: basketQuery } = useOneBasketQuery(
        companyId!,
        user.employeeId
    );
    const basketId = basketQuery?.id;
    const { refetch: refetchBasketProducts } = useGetBasketProducts(
        user?.employeeId,
        basketId ?? "",
        {
            query: { enabled: !!user?.employeeId && !!basketId },
        }
    );
    const { showSidePanel } = useOverlay();

    // Functions
    const onClickProduct = useCallback(() => {
        showSidePanel({
            title: "",
            content: <ProductOverlay product={product} showQuantity={true} />, //</ProductOverlay>// <SearchProductOverlay product={product} />,
            onlyContent: true,
        });
    }, [showSidePanel, product]);

    async function handleOnAdd() {
        const basketProduct: BasketProductDTO = {
            basketId: basketId!,
            productId: product.id ?? "",
            quantity: 1,
            id: "",
            indexPrice: 0,
            refundAmount: 0,
            totalAmount: 0,
            baseAmount: 0,
            creationDate: "0001-01-01",
            modificationDate: "0001-01-01",
            product: {
                id: "",
                creationDate: "0001-01-01",
                modificationDate: "0001-01-01",
                name: "",
                unitPrice: 0,
                isAvailable: false,
                description: "",
                pictureUrl: "",
                promoDescription: "",
                restaurantId: "",
                priority: ProductPriority.Low,
            },
        };

        const response = await BasketProductService.post(
            companyId!,
            user.employeeId,
            basketId!,
            basketProduct
        );

        if (response) {
            queryClient.invalidateQueries({ queryKey: QueryKey.Baskets });
            refetchBasketProducts();
        }
    }

    if (product.isOutOfStock) {
        return (
            <div
                className={
                    "bg-out-of-stock bg-contain bg-no-repeat bg-right rounded-xl border-2 border-red-light flex  bg-white md:min-w-restaurant-product-desktop h-restaurant-product overflow-hidden"
                }
                data-cy={product?.name?.replace(/\s/g, "")}
            >
                <img
                    onClick={onClickProduct}
                    src={product.pictureUrl}
                    className="cursor-pointer object-cover border-r-2 border-red-light h-full min-w-restaurant-product-image"
                    width="10.8125rem"
                />
                <div className="p-3 flex-col flex w-full justify-between overflow-hidden space-y-3">
                    <div className="flex-col flex space-y-1 flex-none!">
                        <Paragraph className="font-semibold font-poppins truncate">
                            {product.name as string}
                        </Paragraph>

                        <Paragraph className="font-poppins font-normal text-xs line-clamp-2">
                            {parse(product?.miniDescription ?? "") as string}
                        </Paragraph>
                    </div>

                    <AddToBasket
                        handleClick={debounce(handleOnAdd, 400, {})}
                        borderRadius="rounded"
                        productPrice={(product?.unitPrice ?? 0).toFixed(2)}
                        isOutOfStock={product.isOutOfStock}
                    />
                </div>
            </div>
        );
    }

    return (
        <div
            className={
                "rounded-xl border-2 border-black flex bg-white md:min-w-restaurant-product-desktop h-restaurant-product overflow-hidden"
            }
            data-cy={product?.name?.replace(/\s/g, "")}
        >
            <img
                onClick={onClickProduct}
                src={product.pictureUrl}
                className="cursor-pointer object-cover border-r-2 border-black h-full min-w-restaurant-product-image"
                width="10.8125rem"
            />
            <div className="p-3 flex-col flex w-full justify-between overflow-hidden space-y-3">
                <div className="flex-col flex space-y-1 flex-none!">
                    <Paragraph className="font-semibold font-poppins truncate">
                        {product.name as string}
                    </Paragraph>

                    <Paragraph className="font-poppins font-normal text-xs line-clamp-2">
                        {parse(product?.miniDescription ?? "") as string}
                    </Paragraph>
                </div>

                <AddToBasket
                    handleClick={debounce(handleOnAdd, 400, {})}
                    borderRadius="rounded"
                    productPrice={(product?.unitPrice ?? 0).toFixed(2)}
                />
            </div>
        </div>
    );
}

export default SearchProductCard;
