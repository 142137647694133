import { App, URLOpenListenerEvent } from "@capacitor/app";
import { useEffect } from "react";
import { useHistory } from "react-router";

const AppUrlListener: React.FC<any> = () => {
    let history = useHistory();
    const companyName = window.localStorage.getItem("COMPANY_NAME") ?? "";

    useEffect(() => {
        App.addListener("appUrlOpen", (data: URLOpenListenerEvent) => {
            // Extract the slug from the URL
            let slug;

            if (data.url.includes("app.foodiz.be")) {
                // Handle web URLs (app.foodiz.be)
                slug = data.url.split("app.foodiz.be").pop();
            } else {
                // Handle other URLs (could be from universal links or other sources)
                // Extract everything after the domain or scheme
                const urlParts = data.url.split("//");
                if (urlParts.length > 1) {
                    // Remove domain if present
                    slug = "/" + urlParts[1].split("/").slice(1).join("/");
                } else {
                    slug = data.url;
                }
            }

            if (slug) {
                // Clean up the slug
                if (slug.includes("://")) {
                    slug = slug.replace("://", "/");
                }

                // Ensure slug starts with a slash
                if (!slug.startsWith("/")) {
                    slug = `/${slug}`;
                }

                // If there is a company name in localStorage and the slug doesn't include it
                if (companyName && !slug.includes(`/${companyName}`)) {
                    slug = `/${companyName}${slug}`;
                }

                history.push(slug);
            }
        });
    }, []);

    return null;
};

export default AppUrlListener;
