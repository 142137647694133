import Input from "../../../../../../components/input";
import { useEffect, useState } from "react";
import Button from "../../../../../../components/button";
import Paragraph from "../../../../../../components/paragraph";
import { useHistory, useParams } from "react-router-dom";
import { CompanyRouteParams } from "../../../../../../types/params/company-route";
import { useTranslation } from "../../../../../../contexts/translation/TranslationContext";
import Service from "../../../../../../services/Service";
import TokenService from "../../../../../../services/TokenService";
import ProfileService from "../../../../../../services/ProfileService";
import { useProfile } from "../../../../../../contexts/profile";
import AuthCard from "../../../../../../components/auth-card";
import {
    AuthRoute,
    FindCompanyRouteParams,
} from "../../../../../../types/params/find-company-route";
import { useConfirmEmail } from "../../../../../../go-services/user/user";
import useRouterQueries from "../../../../../../utils/useRouterQueries";
import * as Sentry from "@sentry/react";
import { Browser } from "@capacitor/browser";
import { errorMapper } from "../../../../../../mappers/message-errors";
import { ErrorType } from "../../../../../../types/enums/error-type";
import { useAnalytics } from "@segment/analytics-react";
import { Capacitor } from "@capacitor/core";
import UnverifiedEmailModal from "./components/unverified-email-modal";

function SignIn() {
    // Attributes
    const { identify } = useAnalytics();
    const history = useHistory();
    const { dispatch } = useProfile();
    const { translate } = useTranslation();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const plat = Capacitor.getPlatform();
    const { companyId } = useParams<CompanyRouteParams>();
    const query = useRouterQueries();
    const token = query.get("token") as string;
    const validateEmail = query.get("email") as string;
    const [companyCode, setCompanyCode] = useState<string>("");
    const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    //Queries
    const { mutateAsync: confirmEmail } = useConfirmEmail({
        mutation: {
            onSuccess: () => {
                setIsEmailConfirmed(true);
                Service.success.push("emailConfirmed");
            },
        },
    });

    // Functions
    async function handleSignInUser() {
        try {
            setIsDisabled(true);

            if (email === "" || password === "") {
                errorMapper(ErrorType.SIGN_IN, "missingCredentialsError");
                setIsDisabled(false);
                return;
            }

            const response = await TokenService.post(email, password);
            if (
                response?.response?.data?.error_description ===
                "Email not confirmed."
            ) {
                setIsModalOpen(true);
            }
            if (!!response.access_token) {
                localStorage.setItem("ACCESS_TOKEN", response.access_token);
                localStorage.setItem("REFRESH_TOKEN", response.refresh_token);

                const profile = await ProfileService.get();

                dispatch({
                    type: "serverChanged",
                    payload: profile,
                    target: "_self",
                });

                history.replace(`/${companyCode}`);
                identify(profile.id, {
                    name: `${profile.lastName} ${profile.firstName}`,
                    email: profile.email,
                    phone: profile.phoneNumber,
                    employeeId: profile.employeeId,
                    device: plat,
                });
            } else {
                setIsDisabled(false);
            }
        } catch (error: any) {
            Sentry.captureException(
                `An error occurred with this email ${email}: `,
                error
            );
        } finally {
            setIsDisabled(false);
        }
    }

    function handleKeyDown(key: any) {
        if (key.key === "Enter") {
            if (email && password) {
                handleSignInUser();
            }
        }
    }

    async function openPrivacyPolicies() {
        Browser.open({
            url: "https://foodizstaging.blob.core.windows.net/files/foodiz_privacy_policies.pdf",
        });
    }

    /**
     * Checks if the user was redirected by the email confirmation process.
     * If no token or email is present in the URL, the function does nothing.
     * If a token and email are present, it triggers the confirmEmail function.
     * @returns {void}
     */
    function handleConfirmEmailCheck() {
        if (
            token === null ||
            token === "" ||
            validateEmail === null ||
            validateEmail === "" ||
            isEmailConfirmed
        ) {
            return;
        }
        confirmEmail({
            data: {
                email: validateEmail,
                token: token,
            },
        });
    }

    // Effects
    useEffect(() => setCompanyCode(companyId), [companyId]);
    useEffect(() => {
        handleConfirmEmailCheck();
    }, [token, validateEmail]);

    // Render
    return (
        <>
            <AuthCard
                onGoBack={() => history?.goBack()}
                footerContent={
                    <div className="flex-col space-y-2">
                        <Button
                            disabled={isDisabled}
                            className="min-w-full bg-add-button"
                            onClick={handleSignInUser}
                            data-cy="sign-in"
                        >
                            {translate("SignIn")}
                        </Button>
                        <div className="flex flex-col items-center md:flex-row justify-center">
                            <Paragraph className="text-gray-DEFAULT flex justify-end text-gray font-light text-xs">
                                {translate("YouDontHaveAnAccount")}
                            </Paragraph>
                            <div
                                onClick={() => {
                                    history.push({
                                        pathname: `/find-company`,
                                        state: {
                                            redirectRoute: AuthRoute.SIGN_UP,
                                        } as FindCompanyRouteParams,
                                    });
                                }}
                            >
                                <Paragraph className="text-custom-green ml-2 flex justify-end text-xs font-semibold cursor-pointer">
                                    {translate("SignUp")}
                                </Paragraph>
                            </div>
                        </div>
                        <div className="flex flex-row space-x-1 justify-center ">
                            <Paragraph className="text-dark-blue flex justify-center font-light text-gray text-xs pt-6">
                                {translate("privacyPolicyCheck")}
                            </Paragraph>
                            <Paragraph
                                onClick={openPrivacyPolicies}
                                className="text-custom-green font-medium flex justify-center text-gray text-xs pt-6 cursor-pointer"
                            >
                                {translate("privacyPolicy")}
                            </Paragraph>
                        </div>
                    </div>
                }
                step={3}
            >
                <div className="mb-8 md:text-center text-left">
                    <Paragraph className="text-3xl text-add-button font-poppins font-semibold">
                        Hi Foodizer👋
                    </Paragraph>
                    <Paragraph className="text-add-button">
                        {translate("SignInToContinue")}
                    </Paragraph>
                </div>
                <div className="flex-col space-y-2">
                    <Input
                        placeholder="Insert email address"
                        type="email"
                        value={email}
                        label="MAIL ADDRESS"
                        labelColorClassName="text-profile-panel-label"
                        onChange={(value: string) => setEmail(value)}
                        onKeyDown={(key) => handleKeyDown(key)}
                        data-cy={"email"}
                    />
                    <div className="max-h-21">
                        <Input
                            placeholder="Insert password"
                            type={"password"}
                            value={password}
                            label={translate("password")}
                            labelColorClassName="text-profile-panel-label"
                            onChange={(value: string) => setPassword(value)}
                            onKeyDown={(key) => handleKeyDown(key)}
                            data-cy={"password"}
                        />
                    </div>
                </div>
                <div
                    onClick={() =>
                        history.push(`/${companyCode}/forgot-password`)
                    }
                >
                    <Paragraph className="text-custom-green flex justify-end font-semibold text-xs cursor-pointer mt-2 mb-8">
                        {translate("ForgotPassword")}
                    </Paragraph>
                </div>
            </AuthCard>
            <UnverifiedEmailModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            />
        </>
    );
}

export default SignIn;
