import Paragraph from "../../../../../../components/paragraph";
import { useOverlay } from "../../../../../../contexts/overlay/overlay-context";
import ProductOverlay from "../product-overlay";
import ProductCardProps from "./props";
import parse from "html-react-parser";
import useMedia from "../../../../../../hooks/useMedia";
import SignInAndSignUpModal from "../sign-in-or-sign-up-modal";
import AddToBasket from "../product-overlay/components/add-to-basket";
import { getGetBasketProductsQueryKey } from "../../../../../../go-services/basket-product/basket-product";
import { useProfile } from "../../../../../../contexts/profile";
import { useQueryClient } from "react-query";
import useBasketLogic from "../../../../../../hooks/useBasketLogic";
import { debounce } from "lodash";
import { useParams } from "react-router-dom";
import { CompanyRouteParams } from "../../../../../../types/params/company-route";
import { useOneCompanyQuery } from "../../../../../../queries/company";
import { useOneBasketQuery } from "../../../../../../queries/basket";
import { QueryKey } from "../../../../../../types/enums/query-key";
import * as Sentry from "@sentry/react";

function ProductCard({ product, isPublic }: ProductCardProps) {
    // Attributes
    const { addProductToBasket, isUpdateBasketLoading } = useBasketLogic();
    const queryClient = useQueryClient();
    const { user } = useProfile();
    const { showSidePanel } = useOverlay();

    const currentMediaQuery = useMedia(
        // Media queries
        ["(min-width: 600px)", "(max-width: 599px)"],
        // Column counts (relates to above media queries by array index)
        [1, 2],
        // Default column count
        1
    );

    // Query
    const { companyId: companyName } = useParams<CompanyRouteParams>();
    const { data: companyQuery } = useOneCompanyQuery(companyName);
    const companyId = companyQuery?.id;
    const { data: currentBasket } = useOneBasketQuery(
        companyId!,
        user.employeeId
    );

    const onClickProduct = (event: React.MouseEvent) => {
        event?.stopPropagation();

        // product panel
        showSidePanel({
            title: "",
            content: (
                <ProductOverlay product={product} showQuantity={!isPublic} />
            ),
            onlyContent: true,
            overflow: currentMediaQuery === 2 ? false : true,
            animate: currentMediaQuery === 2,
        });
    };

    // Handler
    const handleOnAdd = (event: React.MouseEvent) => {
        event?.stopPropagation();

        if (isPublic) {
            showSidePanel({
                title: "",
                content: (
                    <SignInAndSignUpModal
                        productPictureUrl={product?.pictureUrl}
                    />
                ),
                animationDirection: "up",
                overflow: currentMediaQuery === 2 ? false : true,
                popup: currentMediaQuery === 2,
                onlyContent: true,
                isRounded: true,
                isSafeAreaActive: false,
            });
            return;
        }

        try {
            addProductToBasket({
                productId: product?.id ?? "",
                quantity: 1,
                onSuccess: handleBasketUpdateSuccess,
            });
        } catch (error: any) {
            Sentry.captureException(
                `An error occured when adding a product : ${product.id} in basket of user :  ${user.id}`,
                error
            );
        }
    };

    // Success handler
    const handleBasketUpdateSuccess = async () => {
        // Refetch basket
        await queryClient.invalidateQueries({ queryKey: QueryKey.Baskets });
        await queryClient.invalidateQueries(
            getGetBasketProductsQueryKey(
                user?.employeeId ?? "",
                currentBasket?.id ?? ""
            )
        );
    };

    // Render
    if (product.isOutOfStock) {
        return (
            <div
                className={
                    "bg-out-of-stock bg-contain bg-no-repeat bg-right rounded-xl border-2 border-red-light flex  bg-white md:min-w-restaurant-product-desktop h-restaurant-product overflow-hidden"
                }
                data-cy={product?.name?.replace(/\s/g, "")}
            >
                <img
                    onClick={onClickProduct}
                    src={product.pictureUrl}
                    className="cursor-pointer object-cover border-r-2 border-red-light h-full min-w-restaurant-product-image"
                    width="10.8125rem"
                />
                <div className="p-3 flex-col flex w-full justify-between overflow-hidden space-y-3">
                    <div className="flex-col flex space-y-1 flex-none!">
                        <Paragraph className="font-semibold font-poppins truncate">
                            {product.name as string}
                        </Paragraph>

                        <Paragraph className="font-poppins font-normal text-xs line-clamp-2">
                            {parse(product?.miniDescription ?? "") as string}
                        </Paragraph>
                    </div>

                    <AddToBasket
                        handleClick={debounce(handleOnAdd, 400, {})}
                        borderRadius="rounded"
                        productPrice={(product?.unitPrice ?? 0).toFixed(2)}
                        isUpdateBasketLoading={isUpdateBasketLoading}
                        isOutOfStock={product.isOutOfStock}
                    />
                </div>
            </div>
        );
    }

    return (
        <div
            className={
                "rounded-xl border-2 border-black flex bg-white md:min-w-restaurant-product-desktop h-restaurant-product overflow-hidden"
            }
            data-cy={product?.name?.replace(/\s/g, "")}
        >
            <img
                onClick={onClickProduct}
                src={product.pictureUrl}
                className="cursor-pointer object-cover border-r-2 border-black h-full min-w-restaurant-product-image"
                width="10.8125rem"
            />
            <div className="p-3 flex-col flex w-full justify-between overflow-hidden space-y-3">
                <div className="flex-col flex space-y-1 flex-none!">
                    <Paragraph className="font-semibold font-poppins truncate">
                        {product.name as string}
                    </Paragraph>

                    <Paragraph className="font-poppins font-normal text-xs line-clamp-2">
                        {parse(product?.miniDescription ?? "") as string}
                    </Paragraph>
                </div>

                <AddToBasket
                    handleClick={debounce(handleOnAdd, 400, {})}
                    borderRadius="rounded"
                    productPrice={(product?.unitPrice ?? 0).toFixed(2)}
                    isUpdateBasketLoading={isUpdateBasketLoading}
                />
            </div>
        </div>
    );
}

export default ProductCard;
