/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import { useQuery, useMutation } from "react-query";
import type {
    UseQueryOptions,
    UseMutationOptions,
    QueryFunction,
    MutationFunction,
    UseQueryResult,
    QueryKey,
} from "react-query";
import type {
    ProductResDTO,
    ProductReqUpdateDTO,
    ProductPagingResDTO,
    GetRestaurantProductsParams,
    ProductReqCreateDTO,
    GetCompanyRestaurantProductsParams,
    GetAllProductsParams,
    GetSubProductsByProductIdParams,
    GetProductsByTagIdParams,
} from "../../new-types";
import { customInstance } from ".././config";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getProductById = (
    restaurantId: string,
    productId: string,
    signal?: AbortSignal
) => {
    return customInstance<ProductResDTO>({
        url: `/v1/api/restaurants/${restaurantId}/products/${productId}`,
        method: "get",
        signal,
    });
};

export const getGetProductByIdQueryKey = (
    restaurantId: string,
    productId: string
) => [`/v1/api/restaurants/${restaurantId}/products/${productId}`];

export type GetProductByIdQueryResult = NonNullable<
    Awaited<ReturnType<typeof getProductById>>
>;
export type GetProductByIdQueryError = unknown;

export const useGetProductById = <
    TData = Awaited<ReturnType<typeof getProductById>>,
    TError = unknown
>(
    restaurantId: string,
    productId: string,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof getProductById>>,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetProductByIdQueryKey(restaurantId, productId);

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getProductById>>
    > = ({ signal }) => getProductById(restaurantId, productId, signal);

    const query = useQuery<
        Awaited<ReturnType<typeof getProductById>>,
        TError,
        TData
    >({
        queryKey,
        queryFn,
        enabled: !!(restaurantId && productId),
        ...queryOptions,
    }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryKey;

    return query;
};

export const updateProductById = (
    restaurantId: string,
    productId: string,
    productReqUpdateDTO: ProductReqUpdateDTO
) => {
    return customInstance<ProductResDTO>({
        url: `/v1/api/restaurants/${restaurantId}/products/${productId}`,
        method: "put",
        headers: { "Content-Type": "application/json" },
        data: productReqUpdateDTO,
    });
};

export type UpdateProductByIdMutationResult = NonNullable<
    Awaited<ReturnType<typeof updateProductById>>
>;
export type UpdateProductByIdMutationBody = ProductReqUpdateDTO;
export type UpdateProductByIdMutationError = unknown;

export const useUpdateProductById = <
    TError = unknown,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof updateProductById>>,
        TError,
        { restaurantId: string; productId: string; data: ProductReqUpdateDTO },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof updateProductById>>,
        { restaurantId: string; productId: string; data: ProductReqUpdateDTO }
    > = (props) => {
        const { restaurantId, productId, data } = props ?? {};

        return updateProductById(restaurantId, productId, data);
    };

    return useMutation<
        Awaited<ReturnType<typeof updateProductById>>,
        TError,
        { restaurantId: string; productId: string; data: ProductReqUpdateDTO },
        TContext
    >(mutationFn, mutationOptions);
};
export const deleteProductById = (restaurantId: string, productId: string) => {
    return customInstance<ProductResDTO>({
        url: `/v1/api/restaurants/${restaurantId}/products/${productId}`,
        method: "delete",
    });
};

export type DeleteProductByIdMutationResult = NonNullable<
    Awaited<ReturnType<typeof deleteProductById>>
>;

export type DeleteProductByIdMutationError = unknown;

export const useDeleteProductById = <
    TError = unknown,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteProductById>>,
        TError,
        { restaurantId: string; productId: string },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deleteProductById>>,
        { restaurantId: string; productId: string }
    > = (props) => {
        const { restaurantId, productId } = props ?? {};

        return deleteProductById(restaurantId, productId);
    };

    return useMutation<
        Awaited<ReturnType<typeof deleteProductById>>,
        TError,
        { restaurantId: string; productId: string },
        TContext
    >(mutationFn, mutationOptions);
};
export const updateProductAvailibilityById = (
    restaurantId: string,
    productId: string
) => {
    return customInstance<ProductResDTO>({
        url: `/v1/api/restaurants/${restaurantId}/products/${productId}/availibility`,
        method: "patch",
    });
};

export type UpdateProductAvailibilityByIdMutationResult = NonNullable<
    Awaited<ReturnType<typeof updateProductAvailibilityById>>
>;

export type UpdateProductAvailibilityByIdMutationError = unknown;

export const useUpdateProductAvailibilityById = <
    TError = unknown,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof updateProductAvailibilityById>>,
        TError,
        { restaurantId: string; productId: string },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof updateProductAvailibilityById>>,
        { restaurantId: string; productId: string }
    > = (props) => {
        const { restaurantId, productId } = props ?? {};

        return updateProductAvailibilityById(restaurantId, productId);
    };

    return useMutation<
        Awaited<ReturnType<typeof updateProductAvailibilityById>>,
        TError,
        { restaurantId: string; productId: string },
        TContext
    >(mutationFn, mutationOptions);
};
export const updateProductOutOfStockById = (
    restaurantId: string,
    productId: string
) => {
    return customInstance<ProductResDTO>({
        url: `/v1/api/restaurants/${restaurantId}/products/${productId}/out-of-stock`,
        method: "patch",
    });
};

export type UpdateProductOutOfStockByIdMutationResult = NonNullable<
    Awaited<ReturnType<typeof updateProductOutOfStockById>>
>;

export type UpdateProductOutOfStockByIdMutationError = unknown;

export const useUpdateProductOutOfStockById = <
    TError = unknown,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof updateProductOutOfStockById>>,
        TError,
        { restaurantId: string; productId: string },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof updateProductOutOfStockById>>,
        { restaurantId: string; productId: string }
    > = (props) => {
        const { restaurantId, productId } = props ?? {};

        return updateProductOutOfStockById(restaurantId, productId);
    };

    return useMutation<
        Awaited<ReturnType<typeof updateProductOutOfStockById>>,
        TError,
        { restaurantId: string; productId: string },
        TContext
    >(mutationFn, mutationOptions);
};
export const getRestaurantProducts = (
    restaurantId: string,
    params?: GetRestaurantProductsParams,
    signal?: AbortSignal
) => {
    return customInstance<ProductPagingResDTO>({
        url: `/v1/api/restaurants/${restaurantId}/products`,
        method: "get",
        params,
        signal,
    });
};

export const getGetRestaurantProductsQueryKey = (
    restaurantId: string,
    params?: GetRestaurantProductsParams
) => [
    `/v1/api/restaurants/${restaurantId}/products`,
    ...(params ? [params] : []),
];

export type GetRestaurantProductsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRestaurantProducts>>
>;
export type GetRestaurantProductsQueryError = unknown;

export const useGetRestaurantProducts = <
    TData = Awaited<ReturnType<typeof getRestaurantProducts>>,
    TError = unknown
>(
    restaurantId: string,
    params?: GetRestaurantProductsParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof getRestaurantProducts>>,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetRestaurantProductsQueryKey(restaurantId, params);

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRestaurantProducts>>
    > = ({ signal }) => getRestaurantProducts(restaurantId, params, signal);

    const query = useQuery<
        Awaited<ReturnType<typeof getRestaurantProducts>>,
        TError,
        TData
    >({
        queryKey,
        queryFn,
        enabled: !!restaurantId,
        ...queryOptions,
    }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryKey;

    return query;
};

export const createRestaurantProduct = (
    restaurantId: string,
    productReqCreateDTO: ProductReqCreateDTO
) => {
    return customInstance<ProductResDTO>({
        url: `/v1/api/restaurants/${restaurantId}/products`,
        method: "post",
        headers: { "Content-Type": "application/json" },
        data: productReqCreateDTO,
    });
};

export type CreateRestaurantProductMutationResult = NonNullable<
    Awaited<ReturnType<typeof createRestaurantProduct>>
>;
export type CreateRestaurantProductMutationBody = ProductReqCreateDTO;
export type CreateRestaurantProductMutationError = unknown;

export const useCreateRestaurantProduct = <
    TError = unknown,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof createRestaurantProduct>>,
        TError,
        { restaurantId: string; data: ProductReqCreateDTO },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof createRestaurantProduct>>,
        { restaurantId: string; data: ProductReqCreateDTO }
    > = (props) => {
        const { restaurantId, data } = props ?? {};

        return createRestaurantProduct(restaurantId, data);
    };

    return useMutation<
        Awaited<ReturnType<typeof createRestaurantProduct>>,
        TError,
        { restaurantId: string; data: ProductReqCreateDTO },
        TContext
    >(mutationFn, mutationOptions);
};
export const getCompanyRestaurantProducts = (
    companyId: string,
    restaurantId: string,
    params?: GetCompanyRestaurantProductsParams,
    signal?: AbortSignal
) => {
    return customInstance<ProductPagingResDTO>({
        url: `/v1/api/companies/${companyId}/restaurants/${restaurantId}/products`,
        method: "get",
        params,
        signal,
    });
};

export const getGetCompanyRestaurantProductsQueryKey = (
    companyId: string,
    restaurantId: string,
    params?: GetCompanyRestaurantProductsParams
) => [
    `/v1/api/companies/${companyId}/restaurants/${restaurantId}/products`,
    ...(params ? [params] : []),
];

export type GetCompanyRestaurantProductsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getCompanyRestaurantProducts>>
>;
export type GetCompanyRestaurantProductsQueryError = unknown;

export const useGetCompanyRestaurantProducts = <
    TData = Awaited<ReturnType<typeof getCompanyRestaurantProducts>>,
    TError = unknown
>(
    companyId: string,
    restaurantId: string,
    params?: GetCompanyRestaurantProductsParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof getCompanyRestaurantProducts>>,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetCompanyRestaurantProductsQueryKey(
            companyId,
            restaurantId,
            params
        );

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getCompanyRestaurantProducts>>
    > = ({ signal }) =>
        getCompanyRestaurantProducts(companyId, restaurantId, params, signal);

    const query = useQuery<
        Awaited<ReturnType<typeof getCompanyRestaurantProducts>>,
        TError,
        TData
    >({
        queryKey,
        queryFn,
        enabled: !!(companyId && restaurantId),
        ...queryOptions,
    }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryKey;

    return query;
};

export const getAllProducts = (
    params?: GetAllProductsParams,
    signal?: AbortSignal
) => {
    return customInstance<ProductPagingResDTO>({
        url: `/v1/api/products`,
        method: "get",
        params,
        signal,
    });
};

export const getGetAllProductsQueryKey = (params?: GetAllProductsParams) => [
    `/v1/api/products`,
    ...(params ? [params] : []),
];

export type GetAllProductsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getAllProducts>>
>;
export type GetAllProductsQueryError = unknown;

export const useGetAllProducts = <
    TData = Awaited<ReturnType<typeof getAllProducts>>,
    TError = unknown
>(
    params?: GetAllProductsParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof getAllProducts>>,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getGetAllProductsQueryKey(params);

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getAllProducts>>
    > = ({ signal }) => getAllProducts(params, signal);

    const query = useQuery<
        Awaited<ReturnType<typeof getAllProducts>>,
        TError,
        TData
    >({ queryKey, queryFn, ...queryOptions }) as UseQueryResult<
        TData,
        TError
    > & { queryKey: QueryKey };

    query.queryKey = queryKey;

    return query;
};

export const getSubProductsByProductId = (
    productId: string,
    params?: GetSubProductsByProductIdParams,
    signal?: AbortSignal
) => {
    return customInstance<ProductPagingResDTO>({
        url: `/v1/api/products/${productId}/sub-products`,
        method: "get",
        params,
        signal,
    });
};

export const getGetSubProductsByProductIdQueryKey = (
    productId: string,
    params?: GetSubProductsByProductIdParams
) => [
    `/v1/api/products/${productId}/sub-products`,
    ...(params ? [params] : []),
];

export type GetSubProductsByProductIdQueryResult = NonNullable<
    Awaited<ReturnType<typeof getSubProductsByProductId>>
>;
export type GetSubProductsByProductIdQueryError = unknown;

export const useGetSubProductsByProductId = <
    TData = Awaited<ReturnType<typeof getSubProductsByProductId>>,
    TError = unknown
>(
    productId: string,
    params?: GetSubProductsByProductIdParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof getSubProductsByProductId>>,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetSubProductsByProductIdQueryKey(productId, params);

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getSubProductsByProductId>>
    > = ({ signal }) => getSubProductsByProductId(productId, params, signal);

    const query = useQuery<
        Awaited<ReturnType<typeof getSubProductsByProductId>>,
        TError,
        TData
    >({
        queryKey,
        queryFn,
        enabled: !!productId,
        ...queryOptions,
    }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryKey;

    return query;
};

export const getProductsByTagId = (
    tagId: string,
    params?: GetProductsByTagIdParams,
    signal?: AbortSignal
) => {
    return customInstance<ProductPagingResDTO>({
        url: `/v1/api/tags/${tagId}/products`,
        method: "get",
        params,
        signal,
    });
};

export const getGetProductsByTagIdQueryKey = (
    tagId: string,
    params?: GetProductsByTagIdParams
) => [`/v1/api/tags/${tagId}/products`, ...(params ? [params] : [])];

export type GetProductsByTagIdQueryResult = NonNullable<
    Awaited<ReturnType<typeof getProductsByTagId>>
>;
export type GetProductsByTagIdQueryError = unknown;

export const useGetProductsByTagId = <
    TData = Awaited<ReturnType<typeof getProductsByTagId>>,
    TError = unknown
>(
    tagId: string,
    params?: GetProductsByTagIdParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof getProductsByTagId>>,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getGetProductsByTagIdQueryKey(tagId, params);

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getProductsByTagId>>
    > = ({ signal }) => getProductsByTagId(tagId, params, signal);

    const query = useQuery<
        Awaited<ReturnType<typeof getProductsByTagId>>,
        TError,
        TData
    >({
        queryKey,
        queryFn,
        enabled: !!tagId,
        ...queryOptions,
    }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryKey;

    return query;
};

export const getCompanyRestaurantsHighProducts = (
    companyId: string,
    signal?: AbortSignal
) => {
    return customInstance<ProductResDTO[]>({
        url: `/v1/api/companies/${companyId}/products`,
        method: "get",
        signal,
    });
};

export const getGetCompanyRestaurantsHighProductsQueryKey = (
    companyId: string
) => [`/v1/api/companies/${companyId}/products`];

export type GetCompanyRestaurantsHighProductsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getCompanyRestaurantsHighProducts>>
>;
export type GetCompanyRestaurantsHighProductsQueryError = unknown;

export const useGetCompanyRestaurantsHighProducts = <
    TData = Awaited<ReturnType<typeof getCompanyRestaurantsHighProducts>>,
    TError = unknown
>(
    companyId: string,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof getCompanyRestaurantsHighProducts>>,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetCompanyRestaurantsHighProductsQueryKey(companyId);

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getCompanyRestaurantsHighProducts>>
    > = ({ signal }) => getCompanyRestaurantsHighProducts(companyId, signal);

    const query = useQuery<
        Awaited<ReturnType<typeof getCompanyRestaurantsHighProducts>>,
        TError,
        TData
    >({
        queryKey,
        queryFn,
        enabled: !!companyId,
        ...queryOptions,
    }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryKey;

    return query;
};
