import { Capacitor } from "@capacitor/core";
import { useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { useOverlay } from "../../../../../../contexts/overlay/overlay-context";
import { useProfile } from "../../../../../../contexts/profile";
import { useSafeArea } from "../../../../../../contexts/safe-area";
import { getGetBasketProductsQueryKey } from "../../../../../../go-services/basket-product/basket-product";
import {
    useGetBasketSubProducts,
    getGetCurrentBasketQueryKey,
} from "../../../../../../go-services/basket/basket";
import useBasketLogic from "../../../../../../hooks/useBasketLogic";
import useMedia from "../../../../../../hooks/useMedia";
import { ProductResDTO } from "../../../../../../new-types";

import NewBasketProductCard from "../../../basket/components/new-basket-product-card";
import ProductOverlay from "../../../home/components/product-overlay";
import Footer from "./components/footer";
import CrossSellingHeader from "./components/header";
import { CompanyRouteParams } from "../../../../../../types/params/company-route";
import { useOneCompanyQuery } from "../../../../../../queries/company";
import { useOneBasketQuery } from "../../../../../../queries/basket";
import { QueryKey } from "../../../../../../types/enums/query-key";

const CrossSellingOverlay = () => {
    //Attributes
    const { insets } = useSafeArea();
    const queryClient = useQueryClient();
    const { user } = useProfile();
    const { removeCurrentOverlay, showSidePanel, showBasicPanel } =
        useOverlay();
    const [productsQuantity, setProductsQuantity] = useState<{
        [productId: string]: number;
    }>({});
    const { addProductToBasket } = useBasketLogic();
    const currentMediaQuery = useMedia(
        // Media queries
        ["(min-width: 600px)", "(max-width: 599px)"],
        // Column counts (relates to above media queries by array index)
        [1, 2],
        // Default column count
        1
    );

    // Queries
    const { companyId: companyName } = useParams<CompanyRouteParams>();
    const { data: companyQuery } = useOneCompanyQuery(companyName);
    const companyId = companyQuery?.id;
    const { data: currentBasket } = useOneBasketQuery(
        companyId!,
        user.employeeId
    );
    const { data: subProducts } = useGetBasketSubProducts(
        user?.employeeId,
        currentBasket?.id ?? "",
        { isAvailable: true }
    );

    // Check for each products added if there's at least one with quantity > 0
    // This variable serves as a dirty state to know if some products have been selected.
    const isSomeProductsAdded = useMemo(() => {
        return Object.entries(productsQuantity).some(
            ([_, quantity]) => quantity > 0
        );
    }, [productsQuantity]);

    // Handlers
    const handleConfirm = () => {
        if (!isSomeProductsAdded) {
            // If no products added we close the popup and refetch
            handleRefetchBasket();
            return;
        }

        // Else we add each selected products with their selected qty to the basket
        Object.entries(productsQuantity)?.forEach(([productId, quantity]) => {
            addProductToBasket({
                productId,
                quantity,
                onSuccess: handleRefetchBasket,
            });
        });
    };

    // Handlers
    const handleRefetchBasket = () => {
        queryClient.invalidateQueries({ queryKey: QueryKey.Baskets });
        queryClient.invalidateQueries(
            getGetBasketProductsQueryKey(
                user?.employeeId,
                currentBasket?.id ?? ""
            )
        );
        removeCurrentOverlay();
    };

    const handleProductQuantityUpdate = (
        subProduct: ProductResDTO,
        quantity: number
    ) => {
        setProductsQuantity((prev) => {
            const temp = { ...prev };
            if (subProduct?.id) {
                temp[subProduct?.id] = quantity;
            }
            return temp;
        });
    };

    const handleProductClick = (product: ProductResDTO) => {
        // product panel
        showSidePanel({
            title: "",
            content: <ProductOverlay product={product} showQuantity={false} />,
            onlyContent: true,
            onClose: () => {
                showBasicPanel({
                    content: <CrossSellingOverlay />,
                    animate: true,
                });
            },
            overflow: currentMediaQuery === 2 ? false : true,
            animate: currentMediaQuery === 2,
        });
    };

    //Render
    return (
        <div
            className={`z-10 flex flex-col flex-1 flex-grow md:m-auto md:rounded-20px md:w-1/4 md:min-w-side-panel md:max-w-side-panel md:max-h-product-overlay-panel md:h-product-overlay-panel md:my-15! overflow-hidden bg-beige no-scrollbar bottom-0 absolute md:relative h-full w-full max-h-9/10 rounded-t-3xl`}
        >
            {/* Header */}
            <CrossSellingHeader />

            {/* Products */}
            <div className="px-4 py-6 flex flex-col flex-1 space-y-3 overflow-y-auto! no-scrollbar md:max-h-cross-selling-panel bg-beige">
                {subProducts?.data
                    ?.filter((product) => !product?.isOutOfStock)
                    ?.map((subProduct, index) => (
                        <NewBasketProductCard
                            onClick={() => handleProductClick(subProduct)}
                            key={subProduct?.id}
                            name={subProduct?.name ?? ""}
                            pictureUrl={subProduct?.pictureUrl ?? ""}
                            price={subProduct?.unitPrice + "€"}
                            onAddProduct={(updatedQuantity) =>
                                handleProductQuantityUpdate(
                                    subProduct,
                                    updatedQuantity
                                )
                            }
                            onRemoveProduct={(updatedQuantity) =>
                                handleProductQuantityUpdate(
                                    subProduct,
                                    updatedQuantity
                                )
                            }
                            isAvailable={subProduct?.isAvailable}
                            isOutOfStock={subProduct?.isOutOfStock}
                        />
                    ))}
            </div>

            {/* Footer */}
            <div
                className="bg-beige p-4 w-full"
                style={{
                    marginBottom:
                        Capacitor.getPlatform() === "ios"
                            ? insets?.bottom
                            : undefined,
                }}
            >
                <Footer onClick={handleConfirm} confirm={isSomeProductsAdded} />
            </div>
        </div>
    );
};

export default CrossSellingOverlay;
