import React, { useState } from "react";
import { NewBasketProductCardProps } from "./props";
import { ReactComponent as Minus } from "./assets/minus.svg";
import { ReactComponent as Plus } from "./assets/plus.svg";
import Paragraph from "../../../../../../components/paragraph";
import Button from "../../../../../../components/button";

export default function NewBasketProductCard({
    onAddProduct,
    onRemoveProduct,
    name,
    pictureUrl,
    price,
    initialQuantity,
    onClick,
    isAvailable,
    isOutOfStock,
}: NewBasketProductCardProps) {
    // Attributes
    const [quantity, setQuantity] = useState<number>(initialQuantity ?? 0);

    // Handlers
    const handleMinus = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (quantity > 0) {
            let newQuantity = quantity - 1;
            setQuantity(newQuantity);
            onRemoveProduct(newQuantity);
        }
    };

    const handlePlus = (e: React.MouseEvent) => {
        e.stopPropagation();
        let newQuantity = quantity + 1;
        setQuantity(newQuantity);
        onAddProduct(newQuantity);
    };

    // Add new handler for removing all quantity
    const handleRemoveAll = (e: React.MouseEvent) => {
        e.stopPropagation();
        setQuantity(0);
        onRemoveProduct(0);
    };

    // Render
    return (
        <div
            className={`flex justify-start h-20! min-h-20 bg-white rounded-md border overflow-hidden relative ${
                !isAvailable
                    ? "border-red-light border-2! bg-unavailable-small bg-contain bg-right bg-no-repeat"
                    : isOutOfStock
                    ? "border-red-light border-2! bg-out-of-stock-small bg-contain bg-right bg-no-repeat"
                    : "border-black"
            }`}
        >
            <img
                className={`max-h-20 max-w-20 object-cover border-r cursor-pointer ${
                    !isAvailable || isOutOfStock
                        ? "border-red-light border-r-2!"
                        : "border-black"
                }`}
                alt="foodiz"
                src={pictureUrl}
                onClick={onClick}
            />
            <div className="flex flex-col justify-between flex-1 px-2 py-3">
                <div className="flex flex-col justify-between">
                    <div className="flex flex-row items-center justify-between">
                        <div className="text-xs truncate font-semibold flex-1">
                            {name}
                        </div>
                        {isAvailable && !isOutOfStock && (
                            <div className="text-secondary font-semibold text-xs leading-4 bg-white">
                                {price}
                            </div>
                        )}
                    </div>
                </div>

                {/*  Quantity Controls */}
                <div className="flex w-18 items-center justify-between">
                    {!isAvailable || isOutOfStock ? (
                        <Button
                            onClick={handleRemoveAll}
                            className="min-w-full mt-1 max-w-full text-white bg-black rounded-lg max-h-8! px-2! w-auto!  cursor-pointer "
                        >
                            <div className="text-xs  font-semibold">Remove</div>
                        </Button>
                    ) : (
                        <>
                            <div
                                className="cursor-pointer"
                                onClick={handleMinus}
                            >
                                <Minus />
                            </div>
                            <Paragraph className="text-base font-semibold text-black">
                                {quantity}
                            </Paragraph>
                            <div
                                className="cursor-pointer"
                                onClick={handlePlus}
                            >
                                <Plus />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
