import { useHistory, useParams } from "react-router-dom";
import Button from "../../../../../../../../components/button";
import AuthCard from "../../../../../../../../components/auth-card";
import Paragraph from "../../../../../../../../components/paragraph";
import { useProfile } from "../../../../../../../../contexts/profile";
import { CompanyRouteParams } from "../../../../../../../../types/params/company-route";
import { useResendEmailConfirmation } from "../../../../../../../../go-services/user/user";
import Service from "../../../../../../../../services/Service";
import * as Sentry from "@sentry/react";
import Title from "../../../../../../../../components/title";

function ConfirmEmail() {
    // Attributes
    const history = useHistory();
    const { user } = useProfile();
    const { companyId: companyName } = useParams<CompanyRouteParams>();
    const { mutateAsync: resendEmailConfirmation, isLoading: isResendLoading } =
        useResendEmailConfirmation({
            mutation: {
                onSuccess: handleSuccessResendEmail,
                onError: (error: any) => handleErrorResendEmail(error),
            },
        });

    // Functions
    function handleResendEmail() {
        if (isResendLoading) {
            return;
        }
        resendEmailConfirmation({
            data: { email: user.email },
        });
    }

    function handleSuccessResendEmail() {
        Service.success.push("emailSend");
    }
    function handleErrorResendEmail(error: any) {
        Sentry.captureException(
            `An error occured when resending the confirmation email for email : ${user.email}: `,
            error
        );
    }

    // Render
    return (
        <AuthCard
            onGoBack={() => history.goBack()}
            footerContent={<></>}
            showLogo={true}
            step={4}
        >
            <div className="flex flex-col w-full h-full gap-8">
                <div className="flex flex-col gap-8 justify-center h-full">
                    <img
                        width={160}
                        src="/assets/confirm-email.png"
                        className="self-center"
                    />
                    <Title className="font-poppins font-semibold text-3xl! text-center leading-8 m-0!">
                        {"verifyYourEmail"}
                    </Title>
                    <p className="text-center text-xs text-subbody font-poppins font-normal leading-5">
                        Please check your inbox and click the link in the email
                        we sent you to <strong>{user.email}</strong>
                    </p>
                </div>
                <div className="flex flex-col gap-6 justify-end h-full">
                    <div className="flex flex-col gap-1">
                        <Button
                            onClick={() =>
                                history.replace(`/${companyName}/sign-in`)
                            }
                            className="min-w-full bg-add-button "
                        >
                            I’ve verified my email
                        </Button>
                        <Button
                            onClick={handleResendEmail}
                            className="min-w-full bg-white border-none shadow-none"
                        >
                            <p className="text-black">Resend email</p>
                        </Button>
                    </div>
                    <p className="text-center text-xs text-add-button font-poppins font-normal leading-5">
                        You used the wrong e-mail?{" "}
                        <a
                            className="text-xs text-green-dark font-poppins font-semibold leading-5"
                            href={`${companyName}/sign-up/name`}
                        >
                            Sign up again
                        </a>
                    </p>
                </div>
            </div>
        </AuthCard>
    );
}

export default ConfirmEmail;
