import Button from "../../../../../../../../components/button";
import Popup from "../../../../../../../../components/popup";
import { PopUpStyleType } from "../../../../../../../../components/popup/props";
import Title from "../../../../../../../../components/title";

interface UnverifiedEmailModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const UnverifiedEmailModal = ({
    isOpen,
    onClose,
}: UnverifiedEmailModalProps) => {
    return (
        <Popup
            isVisible={isOpen}
            handleOnClose={onClose}
            styleType={PopUpStyleType.Padding32}
        >
            <div className="md:w-390 sm:w-280 flex flex-col items-center space-y-8">
                <img
                    width={123}
                    src="/assets/confirm-email.png"
                    className="self-center"
                />
                <div className="flex flex-col items-center space-y-4">
                    <Title className="text-center text-base! font-poppins! font-semibold! m-0! text-3xl!">
                        unverifiedEmail
                    </Title>
                    <div className="flex flex-col items-center w-250">
                        <p className="text-center font-poppins font-semibold text-xs">
                            You have not yet verified your email.
                        </p>
                        <p className="text-center font-poppins font-normal text-xs">
                            Please check your inbox and click the link in the
                            e-mail we sent you.
                        </p>
                    </div>
                </div>
                <Button onClick={onClose} className="bg-black w-full">
                    I understand
                </Button>
            </div>
        </Popup>
    );
};

export default UnverifiedEmailModal;
