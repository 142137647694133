import { useParams } from "react-router-dom";
import { useGetCompanyByName } from "../../../../go-services/company/company";
import { CompanyRouteParams } from "../../../../types/params/company-route";
import useHomeLogic from "./logic.home";
import useHomeModalsLogic from "./logic.modals";
import useHomeRestaurantsLogic from "./logic.restaurants";
import { IGlobalLogicInput, IGlobalLogicOutput } from "./types";

const useLogic = ({
    currentMediaQuery,
    isPublic,
}: IGlobalLogicInput): IGlobalLogicOutput => {
    // Queries
    const { companyId: companyName } = useParams<CompanyRouteParams>();
    const { data: company } = useGetCompanyByName(companyName as string, {
        query: { enabled: !isPublic && !!companyName },
    });

    // Logics
    const sharedLogic = useHomeLogic({ isPublic, currentMediaQuery, company });

    const modalLogic = useHomeModalsLogic({ isPublic, company });

    const restaurantLogic = useHomeRestaurantsLogic({ isPublic, company });

    // Return
    return { company, ...sharedLogic, ...modalLogic, ...restaurantLogic };
};

export default useLogic;
