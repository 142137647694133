export const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-30%",
        transform: "translate(-50%, -50%)",
        padding: 50,
        border: "none",
        borderRadius: 18,
    },
    overlay: {
        backgroundColor: "rgba(0,0,0,0.5)",
        zIndex: 1000,
    },
};

export const customStylesPadding32 = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-30%",
        transform: "translate(-50%, -50%)",
        padding: 32,
        border: "none",
        borderRadius: 18,
    },
    overlay: {
        backgroundColor: "rgba(0,0,0,0.5)",
        zIndex: 1000,
    },
};

export const fullscreenStyle = {
    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 1000,
    },
    content: {
        width: "100%",
        height: "100%",
        padding: "0 !important",
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "0",
        border: "none",
        backgroundColor: "transparent",
        boxShadow: "none",
    },
};
